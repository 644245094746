import React from "react";
import { AdminF } from "../common/config";
import { getWxConfig, getSystemItem } from "../common/api";
import { isWeixin, getUrlCrop } from "../common/libs";

export default class BaseComponents extends React.Component {
	constructor(props){
		super(props)
		if(!this.shareImg) {
			this.shareImg =
				"https://try-chspu-com.oss-cn-shanghai.aliyuncs.com/common/sharelogo.png";
		}
		this.page_title = props.page_title;
	}
	getF() {
		const { match } = this.props;
		return match.params.f || AdminF;
	}
	getUserToken() {
		let userInfo = localStorage.getItem(`f_${this.getF()}`);
		try {
			userInfo = JSON.parse(userInfo);
		} catch (e) {
			userInfo = { token: "", tel: "" };
		}
		return userInfo;
	}
	initWx() {
		const wx = window.wx;
		const { shareTitle, shareContent, shareImg } = this;
		const shareData = {
			title: shareTitle,
			link: window.location.href,
			icon: getUrlCrop(shareImg, 128, 128, true),
			desc: shareContent
		}
		if (isWeixin()) {
			getWxConfig({
				url: encodeURIComponent(window.location.href),
				f: this.getF()
			}).then(res => {
				console.log(res);
				wx.config({
					debug: false,
					appId: res.appId, // 必填，公众号的唯一标识
					timestamp: res.timestamp, // 必填，生成签名的时间戳
					nonceStr: res.nonceStr, // 必填，生成签名的随机串
					signature: res.signature, // 必填，签名，见附录1
					jsApiList: res.jsApiList
				});
				wx.ready(function() {
					//老方法：兼容低版本微信，官方文档即将废弃18.11.4
					//分享到朋友圈及QQ空间
					wx.onMenuShareTimeline({
						title: shareData.title, // 分享标题
						link: shareData.link, // 分享链接
						imgUrl: shareData.icon // 分享图标
					});
					//分享给朋友及QQ
					wx.onMenuShareAppMessage({
						title: shareData.title, // 分享标题
						desc: shareData.desc, // 分享描述
						link: shareData.link, // 分享链接
						imgUrl: shareData.icon // 分享图标
					});
					//分享到腾讯微博
					wx.onMenuShareWeibo({
						title: shareData.title, // 分享标题
						desc: shareData.desc, // 分享描述
						link: shareData.link, // 分享链接
						imgUrl: shareData.icon // 分享图标
					});
					//新方法
					//分享到朋友圈及QQ空间
					wx.updateTimelineShareData &&
						wx.updateTimelineShareData({
							title: shareData.title, // 分享标题
							link: shareData.link, // 分享链接
							imgUrl: shareData.icon // 分享图标
						});
					//分享给朋友及QQ
					wx.updateAppMessageShareData &&
						wx.updateAppMessageShareData({
							title: shareData.title, // 分享标题
							desc: shareData.desc, // 分享描述
							link: shareData.link, // 分享链接
							imgUrl: shareData.icon // 分享图标
						});
					//分享到腾讯微博
					wx.onMenuShareWeibo &&
						wx.onMenuShareWeibo({
							title: shareData.title, // 分享标题
							desc: shareData.desc, // 分享描述
							link: shareData.link, // 分享链接
							imgUrl: shareData.icon // 分享图标
						});
				});
			});
		}
	}
	getDefaultShare() {
		getSystemItem({
			f: this.getF(),
			type: 8
		}).then(res=>{
			if(res.code !== 0) {
				return
			}
			const {list} = res.data;
			if(list.length ===0) {
				return;
			}
			const shareContent = list[0];
			this.shareTitle = shareContent['title'];
			this.shareContent = shareContent['value'];
			this.initWx()
		})
	}
	sendTJlog(label, page) {
		if (this.getF()) {
			label = label + '_' + this.getF();
		}
		if(!page) {
			page = this.page_title;
		} 
		window._hmt && window._hmt.push(["_trackEvent", label, "点击", page]);
	}
}
