import axios from "axios";
import { msgShow } from "./libs";

// const baseUrl = "//trial.xbpig.cn/";
const baseUrl = "/";

const ajaxBase = (url, data = {}, type = "post") => {
	const requestParam = {
		url: baseUrl + url,
		method: type,
		responseType: "text"
	};
	if (type === "get") {
		requestParam.params = data;
	} else if (type === "post") {
		requestParam.data = data;
	} else if (type === "formdata") {
		requestParam.method = "post";
	}
	return axios(requestParam)
		.then(res => {
			const { data: resData } = res;
			if (resData.code !== 0) {
				msgShow(resData.msg);
			}
			return res.data;
		})
		.catch(res => {
			msgShow("坏了出错了");
			return {
				code: 1
			};
		});
};

export const getProductList = data => {
	return ajaxBase("api/product/list", data, "get");
};
export const getProductListAll = data => {
	return ajaxBase("api/product/all", data, "get");
};

export const getProductDetail = data => {
	return ajaxBase("api/product/detail", data, "get");
};

export const getSystemInfo = data => {
	return ajaxBase("api/system/getPage", data, "get");
};
export const getSystemItem = data => {
	return ajaxBase("api/system/get", data, "get");
};

export const uploadImg = data => {
	return ajaxBase("api/request/pic", data, "post");
};
export const addAdvice = data => {
	return ajaxBase("api/advice/add", data, "post");
};
export const getReportList = data => {
	return ajaxBase("api/report/list", data, "get");
};

export const getMiniDetail = data => {
	return ajaxBase("api/product/miniDetail", data, "get");
};

export const getSmsCode = data => {
	return ajaxBase("api/request/sms", data, "post");
};

export const addRequestInfo = data => {
	return ajaxBase("api/request/report", data, "post");
};

export const addObigate = data => {
	return ajaxBase("api/request/obligate", data, "post");
};

export const getProductSelect = data => {
	return ajaxBase("api/product/select", data, "post");
};

export const addReport = data => {
	return ajaxBase("api/report/add", data, "post");
};

export const addObligate = data => {
	return ajaxBase("api/request/obligate", data, "post");
};

export const getWxConfig = data => {
	return ajaxBase("api/wechat/config", data, 'get');
}
