import React from "react";
import { getProductList, getProductListAll } from "../../common/api";
import { getUrlCrop } from "../../common/libs";
import { AdminF } from "../../common/config";
import layer from "layer-mobile";
import BaseComponents from "../../compoents/Base";
import backTop from "../../static/images/back_top.png";
import $ from "jquery";

export default class Index extends BaseComponents {
	state = {
		productList: [],
		pageIndex: 0,
		pageSize: 10,
		orderBy: "", //asc,desc
		order: "" //hot,time
	};
	constructor(props) {
		super(props);
		this.state.history = props.history;
		this.loadingFlag = true;
		this.isPageAll = false;
	}
	componentWillMount() {
		this.getProductList();
		this.getDefaultShare();
	}
	componentDidMount() {
		const winHeight =
			window.innerHeight || document.documentElement.clientHeight;
		const $backTop = $(".common-back-top");
		$(window).scroll(res => {
			var $scrollH = $(window).scrollTop(),
				$winH = $(window).height(),
				$pageH = $(document).height();

			if ($scrollH > winHeight) {
				$backTop.fadeIn(1000);
			} else {
				$backTop.fadeOut(1000);
			}

			if ($scrollH + $winH >= $pageH && this.loadingFlag) {
				this.getProductList();
			}
		});
	}
	componentWillUnmount() {
		$(window).off("scroll");
	}
	getProductList() {
		let { pageIndex, pageSize, orderBy, order, productList = [] } = this.state;
		const f = this.getF();
		let responseObj;

		this.loadingFlag = false;

		if (!this.isPageAll) {
			pageIndex += 1;
		}else {
			return;
		}

		if (f && f !== AdminF) {
			responseObj = getProductList({
				pageIndex,
				pageSize,
				orderBy,
				order,
				f
			});
		} else {
			responseObj = getProductListAll({
				pageIndex,
				pageSize,
				orderBy,
				order,
				f
			});
		}
		responseObj
			.then(res => {
				if (res.code !== 0) {
					return;
				}
				let { list } = res.data;
				if (res.code !== 0) {
					return;
				}
				if (list.length < pageSize) {
					this.isPageAll = true;
				}
				if (list.length === 0) {
					pageIndex -= 1;
				}
				list = list.map((item, index) => {
					item.postUrl = getUrlCrop(item.postUrl);
					if (item.nowRequest > 10000) {
						item.nowRequest = "9999+";
                    }
                    if (parseInt(item.productType, 10) === 1) {
                          item.finishClass = "product-info";
                          item.btnText = "填写问卷";
                    } else {
                        if (item.surplusRequest <= 0) {
                            item.finishClass = "write-userinfo";
                            item.btnText = "预约申请";
                        } else {
                            item.finishClass = "product-info";
                            item.btnText = "申请体验";
                        }
                    }
					item.f = item.f || f;
					productList.push(item);
					return item;
				});
				this.setState({
					productList,
					pageIndex,
					isPageAll: this.isPageAll || false
				});
			})
			.then(res => {
				this.loadingFlag = true;
			});
	}
	productItemClick(value, e, type = "item") {
		const { productId, f } = value;
		const { history } = this.state;

		if (type === "btn") {
			if (value.surplusRequest <= 0) {
				layer.open({
					content:
						"Sorry，此产品本期体验已经结束，" +
						"<p>您可以预留信息，下期体验开始，</p>" +
						"会以短信的形式提醒您～",
					btn: ["立即预留", "再逛逛"],
					yes: index => {
						layer.close(index);
						history.push({
							pathname: `/obligate/${f}/${productId}`
						});
						// window.location.href = 'write_userinfo.html?productId=' + id + '&f=' + f;
					}
				});
				this.sendTJlog("申请预留");
				return e.stopPropagation();
			}
		}
		if (f) {
			this.sendTJlog("申请试用");
			history.push({
				pathname: `/product/${f}/${productId}`
			});
		}
		return e.stopPropagation();
	}
	sortByFunc(type) {
		let { orderBy } = this.state;
		if (type === "time") {
			this.sendTJlog("时间");
			if (orderBy === "asc") {
				orderBy = "desc";
			} else {
				orderBy = "asc";
			}
		} else {
			this.sendTJlog("人气");
			orderBy = "";
		}
		this.setState(
			{
				order: type,
				orderBy,
				productList:[],
				pageIndex:0
			},
			() => {
				this.isPageAll=false;
				this.getProductList();
			}
		);
	}
	goToTop() {
		$("html,body").animate({ scrollTop: 0 }, 500);
	}
	render() {
		let { productList, orderBy, order, isPageAll } = this.state;
		return (
			<div className="page-index content tab">
				<div className="tab-tit tab-title">
					<span
						className={`hot-order ${order === "hot" ? "selected" : ""}`}
						data-track="人气"
						data-page="体验中心"
						onClick={e => this.sortByFunc("hot")}
					>
						人气
					</span>
					<span
						className={`time-order ${order === "time" ? "red" : ""}`}
						data-track="时间"
						data-page="体验中心"
						onClick={e => this.sortByFunc("time")}
					>
						时间
						<em className="arrow">
							<i
								className={`arrow-up ${
									orderBy === "asc" ? "arrow-up-selected" : ""
								}`}
							/>
							<i
								className={`arrow-down ${
									orderBy === "desc" ? "arrow-down-selected" : ""
								}`}
							/>
						</em>
					</span>
				</div>
				<div className="tab-content product-list">
					<ul className="tab-content product-list">
						{productList.map((value, index) => {
							return (
								<li
									data-page="产品-体验中心"
									key={index}
									data-id="{{value.productId}}"
									onClick={e => this.productItemClick(value, e, "item")}
								>
									<div className="li-bg">
										<div
											className="product-info"
											data-page="产品-体验中心"
											data-id="{{value.productId}}"
											data-f="{{value.f}}"
										>
											<img src={value.postUrl} alt="" />
											<div className="product-text">
                                                <h3>{value.productName}</h3>
                                                {value.productPrice > 0 ? (
                                                    <div className="common-price-wapper">
													<span>
														<span className="red">参考价</span>
														<span className="price">
															<i>￥</i>
															{value.productPrice}
														</span>
													</span>
													<span className="price-tips">免 费</span>
												</div>
                                                 ):( <div className="common-price-wapper"></div>)}
												
											</div>
										</div>
										<div className="apply">
											<div
												className="apply-sum product-info"
												data-page="产品-体验中心"
												data-id="{{value.productId}}"
												data-f="{{value.f}}"
											>
												<p>
													{value.nowRequest}
													人申请
												</p>
												<p>
													剩<em>{value.surplusRequest}</em>/{value.rulesRequest}
													份
												</p>
											</div>
											<div
												className={`apply-btn ${value.finishClass}`}
												data-page="产品-体验中心"
												data-id="{{value.productId}}"
												data-f="{{value.f}}"
												onClick={e => this.productItemClick(value, e, "btn")}
											>
												{value.btnText}
											</div>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
				<div className="tips">{isPageAll ? "客官，就这些了~" : ""}</div>
				<div className="common-back-top" onClick={e => this.goToTop()}>
					<img src={backTop} alt="返回顶部" />
				</div>
			</div>
		);
	}
}
