import React from "react";
import { Link, withRouter } from "react-router-dom";
import BaseComponents from "./Base";

export default class Footer extends BaseComponents {
	componentWillMount(){
        this.__resetFooterType(this.props)
    }
    componentWillReceiveProps(props) {
        this.__resetFooterType(props)
    }
    __resetFooterType(props) {
        const {fromType} = props
        this.props = props
        if (fromType === 'productItem') {
            this.getFooterList("btn_request");
        } else if (fromType === 'productItem_questionnaire') {
            this.getFooterList("btn_questionnaire");
        } else {
            this.getFooterList()

        }
    }
	getFooterList(other) {
		const f = this.getF();
        const {type, onclick, footer = true, productUrl} = this.props;
		let navType = {
			index: {
				link: `/index/${f}`,
				text: "　首页　",
				icon: {
					default: "&#xe61f;",
					select: "&#xe620;"
				}
			},
			about: {
				link: `/about/${f}`,
				text: "关于我们",
				icon: {
					default: "&#xe627;",
					select: "&#xe7a9;"
				}
			},
			help: {
				link: `/about/${f}`,
				text: "帮助",
				icon: {
					default: "&#xe626;",
					select: ""
				}
			},
			report: {
				link: `/report/${f}`,
				text: "提交体验报告",
				icon: {
					default: "&#xe516;",
					select: ""
				}
			},
			feedback: {
				link: `/feedback/${f}`,
				text: "问题反馈",
				icon: {
					default: "&#xe75b;",
					select: ""
				}
			},
			contact: {
				link: `/about/${f}`,
				text: "联系我们",
				icon: {
					default: "&#xe7c1;"
				}
			},
			btn_feedback: {
				className: "product-bar report-submit-btn",
				text: "确认提交",
				btn: true,
				func: () => {
					onclick && onclick("feedback");
				}
			},
			btn_buy: {
                className: `product-bar product-bar-btn ${!productUrl?'btn-grey':''}`,
				text: "正品购买",
				btn: true,
				func: () => {
					onclick && onclick("btn_buy");
				}
			},
			btn_request: {
				className: "product-bar report-submit-btn",
				text: "申请体验",
				btn: true,
				func: () => {
					onclick && onclick("btn_reqeust");
				}
            },
            btn_questionnaire: {
                className: "product-bar report-submit-btn",
                text: "提交问卷",
                btn: true,
                func: () => {
                    onclick && onclick("btn_questionnaire");
                }
            },
			btn_writeReport: {
				className: "product-bar report-submit-btn",
				text: "确认提交",
				btn: true,
				func: () => {
					onclick && onclick("btn_writeReport");
				}
			},
			none: {
				className: " ",
				text: "",
				btn: true
			}
		};
		let navList;

		if (type === "feedback") {
			navList = ["index", "contact", "none", "btn_feedback"];
		}
		switch (type) {
			case "reportSuccess":
				navList = [];
				break;
			case "feedback":
				navList = ["index", "contact", "none", "btn_feedback"];
				break;
            case "productItem":
                if (other == 'btn_questionnaire') {
                    navList = ["index", "help", "feedback", "btn_buy", "btn_questionnaire"];
                } else {
                    navList = ["index", "help", "feedback", "btn_buy", "btn_request"];
                }
				break;
			case "requestAdd":
                navList = ["index", "contact", "none", "btn_request"];
				break;
			case "writeReport":
				navList = ["help", "contact", "none", "btn_writeReport"];
				break;
			case "about":
				navList = ["index", "about", "feedback"];
				break;
			default:
				navList = ["index", "about", "report", "feedback"];
				break;
		}
		if (!footer) {
			navList = [];
		}

		navList =  navList.map(item => {
			let obj = navType[item] || {};
			obj.selected = item === type ? true : false;
            obj.type = item;
            if (type == 'productItem') {
                if (other && item === other && other == 'btn_request') {
                    obj.text = "预约申请";
                    obj.func = () => {
                        onclick && onclick('btn_obligate');
                    }
                }
            }
			
			return obj;
		});
		this.setState ( {
			navList
		});
	}
	render() {
		const {navList} = this.state

		return (
			<div
				className={`common-bottom-bar ${navList.length === 0 ? "none" : ""}`}
			>
				{navList.map((item, index) => {
                    if (!item.btn) {
                        let icon;
                        if (item.selected) {
                            icon = item.icon && item.icon.select
                        } else {
                            icon = item.icon && item.icon.default
                        }
						return (
							<div
								className={`bottom-bar-nav ${item.selected ? "selected" : ""}`}
								key={index}
							>
								<Link
									to={{ pathname: item.link }}
									onClick={e => this.sendTJlog(item.text)}
								>
									<i
										className="icon iconfont"
										dangerouslySetInnerHTML={{
                                            __html: icon
										}}
									/>
									<h2>{item.text}</h2>
								</Link>
							</div>
						);
					} else {
						return (
							<div
								className={`bottom-bar-nav ${item.className}`}
								key={index}
								onClick={e => {
									item.func && item.func();
								}}
							>
								<h2>{item.text}</h2>
							</div>
						);
					}
				})}
			</div>
		);
	}
}

withRouter(Footer);
