import layer from "layer-mobile";

export const msgShow = (msg, time = 2) => {
	if (!msg) return;
	layer.open({
		content: msg,
		skin: "msg",
		time: time
	});
};
export const ModalShow = (msg) => {
    if (!msg) return;
    layer.open({
        content: msg, btn: '我知道了'
    });
}
export const startLoading = (msg = "加载中...") => {
	layer.open({
		type: 2,
		content: msg
	});
};
export const endLoading = () => {
	layer.closeAll();
};

export const getLocationParam = p => {
	var url = p ? p : window.location.search;
	var params = url
		.toString()
		.slice(1)
		.split("&");
	var returnObject = {};
	for (var i = 0; i !== params.length; i++) {
		var index = params[i].indexOf("=");
		returnObject[params[i].slice(0, index)] = params[i].slice(index + 1);
	}
	return returnObject;
};
export const getParam = url => {
	url = decodeURIComponent(url);
	var params = url ? url.toString().split("&") : [];
	var returnObject = {};

	for (var i = 0; i !== params.length; i++) {
		var canshu = params[i].split("=");
		returnObject[canshu[0]] = canshu[1];
	}
	return returnObject;
};
export const getUrlCrop = (url, w, h, http = false) => {
	if (!http) {
		url = url.replace("http:", "");
	}
	if (getParam(url)["x-oss-process"]) {
		return url;
	}
	if (!w) w = 320;
	if (!h) h = 320;
	return (
		url + "?x-oss-process=image/resize,w_" + w + ",h_" + h + ",limit_0,m_lfit"
	);
};

export const formatPhone = phone => {
	return phone && phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
};

export const checkTel = tel => {
	if (
		!/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(
			tel
		)
	) {
		return false;
	}
	return true;
};

export const isWeixin = () => {
	var ua = navigator.userAgent.toLowerCase();
	return /micromessenger/.test(ua) ? true : false;
};
