import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Loadable from "react-loadable";
import Index from "./pages/Index";
import Header from "./compoents/Header";
import Footer from "./compoents/Footer";




const Loading = () => {
	return (
		<div className="sweet-loading">
			{/* <BarLoader
				css={override}
				sizeUnit={"px"}
				size={150}
				color={"#123abc"}
				loading={true}
			/> */}
		</div>
	);
}
const routes = [
	{
		path: "/index/:f?",
		component: Index,
		type: "index",
		exact: true,
		page_title: "体验中心"
	},
	{
		path: "/product/:f/:id",
		component: Loadable({
			loader: () => import("./pages/product"),
			loading() {
				return <Loading />;
			}
		}),
		type: "productItem",
		exact: true,
		page_title: "产品详情"
	},
	{
		path: "/about/:f",
		component: Loadable({
			loader: () => import("./pages/About"),
			loading() {
				return <Loading />;
			}
		}),
		type: "about",
		title: "关于我们",
		page_title: "关于我们",
		exact: true,
		share: false
	},
	{
		path: "/feedback/:f",
		component: Loadable({
			loader: () => import("./pages/Feedback"),
			loading() {
				return <Loading />;
			}
		}),
		type: "feedback",
		title: "问题反馈",
		page_title: "问题反馈",
		exact: true,
		share: false
	},
	{
		path: "/reportSuccess/:f/:from?",
		component: Loadable({
			loader: () => import("./pages/Report/success"),
			loading() {
				return <Loading />;
			}
		}),
		type: "reportSuccess",
		title: "提交成功",
		page_title: "提交成功",
		exact: true,
		share: false
	},
	{
		path: "/reportList/:f/:activeId?",
		component: Loadable({
			loader: () => import("./pages/Report/AllList"),
			loading() {
				return <Loading />;
			}
		}),
		type: "reportList",
		page_title: "报告列表",
		footer: false
	},
	{
        path: "/request/:f/:activeId?/:serialNumber?",
		component: Loadable({
			loader: () => import("./pages/Request"),
			loading() {
				return <Loading />;
			}
		}),
		type: "requestAdd",
		title: "填写收货信息",
		page_title: "填写收货信息",
		share: false
	},
	{
		path: "/report/:f/:activeId?",
		component: Loadable({
			loader: () => import("./pages/Report/addReport"),
			loading() {
				return <Loading />;
			}
		}),
		type: "AddReport",
		title: "选择产品",
		page_title: "选择产品",
		share: false,
		footer: false
	},
	{
		path: "/writeReport/:f/:activeId?",
		component: Loadable({
			loader: () => import("./pages/Report/writeReport"),
			loading() {
				return <Loading />;
			}
		}),
		type: "writeReport",
		title: "健康社区体验中心报告",
		page_title: "填写报告",
		share: false
    },
    {
        path: "/questionnaire",
        component: Loadable({
            loader: () => import("./pages/Request/question"),
            loading() {
                return <Loading />;
            }
        }),
        type: "questionnaire",
        title: "健康社区体验中心报告",
        page_title: "填写报告",
        share: false
    },
	{
		path: "/obligate/:f/:activeId?",
		component: Loadable({
			loader: () => import("./pages/Request/obligate"),
			loading() {
				return <Loading />;
			}
		}),
		type: "obligate",
		title: "预留信息",
		page_title: "预留信息",
		share: false,
		footer: false
	}
];

export default class RouteConfigExample extends React.Component {
	state = {
		footerClickType: "",
		fromOther: {}
	};
	footerBtnFunc(type) {
		this.setState({
			footerClickType: type
		});
	}
	headerFunc(msg) {
		this.setState({
			fromOther: msg
		});
	}
	render() {
		const { footerClickType, fromOther } = this.state;
		return (
			<Router>
				<div>
					{routes.map((route, i) => (
						<Route
							key={i}
							exact={route.exact}
							path={route.path}
							render={props => (
								<div className="router-container">
									<Header
										{...props}
										{...route}
										onMsg={msg => this.headerFunc(msg)}
									/>
									<route.component
										{...props}
										type={route.type}
										footerClickType={footerClickType}
										onMsg={msg => this.headerFunc(msg)}
										routes={route.routes}
										{...route}
									/>
									{route.type !== "productItem" ? (
										<Footer
											{...props}
											onclick={type => this.footerBtnFunc(type)}
											callFooter={fromOther}
											{...route}
										/>
									) : (
										""
									)}
								</div>
							)}
						/>
					))}
				</div>
			</Router>
		);
	}
}
