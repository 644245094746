import React from "react";
import logo from "../static/images/logo.png";
import share from "../static/images/share.png";
import { isWeixin, msgShow } from "../common/libs";
import BaseComponents from "./Base";
import layer from "layer-mobile";
import NativeShare from "nativeshare";

export default class Header extends BaseComponents {
	goBack() {
		// this.props.history.goBack();
		const { history } = this.props;
		this.sendTJlog("返回");
		history.goBack();
	}
	shareHandle(img) {
		this.sendTJlog("分享");
		const {shareTitle, shareContent, shareImg} = this;
		if (isWeixin()) {
			layer.open({
				className: "share-mask",
				style: "border:none",
				content: img || `<img src="${share}">`
			});
		} else{
			var nativeShare = new NativeShare();
			nativeShare.setShareData({
				icon: shareImg,
				link: window.location.href,
				title: shareTitle,
				desc: shareContent,
			});
			try {
				nativeShare.call()
			}catch(e){
				msgShow("请点击底部分享~");
			}
		}
	}
	render() {
		const { title, share = true } = this.props;
		return (
			<div className="common-top">
				<div
					className="common-top-back"
					onClick={e => this.goBack(e)}
				>
					<i className="icon iconfont">&#xe606;</i>
				</div>
				<h1 className="common-top-tit">
					{!title ? <img src={logo} alt="logo" /> : ""}
					{title ? title : "体验中心"}
				</h1>
				{share ? (
					<div
						className="common-top-share"
						onClick={e => this.shareHandle()}
					>
						<i className="icon iconfont">&#xe633;</i>
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}
